import React from "react"
import { Trans, useTranslation } from "../translation/translate.component"
import { useFormikContext } from "formik"
import styled from "styled-components"
import PropTypes from "prop-types"

const ClickHere = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export function DifferentBilling ({ field }) {
  const { t } = useTranslation()
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const { name, value } = field

  return (
    <div>
      <Trans
        t={t}
        i18nKey={
          value
            ? `common:payment.page_step2_different_billing_same`
            : `common:payment.page_step2_different_billing_different`
        }
        components={{
          open: (
            <ClickHere
              data-testid={`form_field_${name}`}
              onMouseDown={e => {
                e.preventDefault()

                setFieldValue(name, !value)
                setFieldTouched(name, true)
              }}
            />
          ),
        }}
      />
    </div>
  )
}

DifferentBilling.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
}
