import React, { useState } from "react"
import PropTypes from "prop-types"
import TunnelCustomer from "../tunnel/customer/tunnelCustomer.component"
import { usePaymentContext } from "./payment.provider"
import RadioGroup from "../../components/form/radioGroup.component"
import { required, compose, tel, ensura, email, mini, noNumber } from "../../utils/validate"
import InputText from "../../components/form/inputText.component"
import Address from "../../components/form/address.component"
import { PaymentCnilMention } from "./paymentCnilMention.component"
import { cedilleMask, telMask } from "../../utils/mask"
import styled from "styled-components"
import Checkbox from "../../components/form/checkbox.component"
import { useThemeContext } from "../structure/theme"
import Modal from "../../components/modal/modal.component"
import { postPurchasesBasket } from "./payment.api"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import { formTrackingErrors, usePaymentProductTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import { Error } from "../../components/form/infoAndError.component"
import PaymentFormFooter from "./paymentFormFooter.component"
import { useCampaignProduct } from "../campaign/campaign.utils"
import { sentryCaptureException } from "../thirdParties/sentry"
import { useCampaignContext } from "../campaign/campaign.provider"
import { usePrice } from "../product/product.utils"
import SubmitButton from "../../components/form/submitButton.component"
import { useBrandName } from "../branding/branding.hooks"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import { usePaymentErrorData } from "./payment.utils"
import { usePageContext } from "../navigation/pageContext.provider"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import { useDraft } from "../../components/form/plugins/draft.component"
import { usePersist } from "../../components/form/plugins/persist.component"
import { useValueChanges } from "../../components/form/plugins/valueChanges.component"
import { DifferentBilling } from "./differentBilling.component"
import { useCaptcha } from "../../components/form/plugins/captcha.component"
import { omit } from "../../utils/collection"

const EligibilityModal = loadable(() => import(`./eligibilityModal.component`), { ssr: false })

const AdviceText = styled.div`
  margin-bottom: 20px;
`

const formInitialState = {
  email: ``,
  civility: ``,
  firstname: ``,
  lastname: ``,
  phone: ``,
  differentBilling: false,
  billingPostalCodeAndCity: ``,
  billingPostalCode: ``,
  billingCity: ``,
  billingAddress: ``,
  billingStreet: ``,
  billingStreetNumber: ``,
  billingResidence: ``,
  emailOptin: false,
}

function validate (values, t) {
  return {
    civility: required(values.civility),
    firstname: compose(required, ensura, mini(2), noNumber)(values.firstname),
    lastname: compose(required, ensura, mini(2), noNumber)(values.lastname),
    phone: compose(required, tel)(values.phone),
    email: compose(required, email)(values.email),
    billingPostalCodeAndCity:
      values.differentBilling &&
      compose(
        _values => required(_values.billingCity),
        _values => required(_values.billingPostalCode),
      )(values),
    billingAddress:
      values.differentBilling &&
      compose(() => {
        if (!values.billingCity || !values.billingPostalCode) {
          return t(`common:payment.page_step2_validation_message`)
        }

        return null
      }, required)(values.billingAddress),
    billingResidence: values.differentBilling && values.billingResidence && ensura(values.billingResidence),
  }
}

export default function Step2Page ({ data }) {
  const { cnilMentionCustomerForm } = data.websiteConfigurationLocale
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { locale } = usePageContext()
  const {
    colors: { grey },
  } = useThemeContext()
  const { isReady, product, step1, step2, setStep2AndSessionId } = usePaymentContext()
  const [eligibilityModal, setEligibilityModal] = useState(false)
  const [modalProps, setModalProps] = useState(() => ({
    title: t(`common:payment.page_step2_modal_title_default`),
    error: t(`common:payment.page_step2_modal_error_default`),
  }))
  const [errorForm, setErrorForm] = useState(``)
  const [btnActive, setBtnActive] = useState(true)
  const [campaignProduct, campaignProductReady] = useCampaignProduct(product)
  const { voucher } = useCampaignContext()
  const { voucherApplied } = usePrice(campaignProduct)
  const brandName = useBrandName()
  const paymentErrorData = usePaymentErrorData(product)

  useViewPageTracking(`TunnelCommande`, ``, `Tunnel - Coordonnées`)
  usePaymentProductTracking(product, 2, `Coordonnées`)

  function onSubmit ({ captchaToken, ...values }) {
    setBtnActive(false)

    return postPurchasesBasket(
      campaignProduct,
      step1,
      omit(values, `billingPostalCodeAndCity`),
      voucherApplied && voucher && voucher.code,
      locale,
      captchaToken,
    )
  }

  function onSubmitSuccess () {
    navigate(`paymentStep3`)
  }

  function onSubmitFail (values, { setFieldError }, error) {
    const errorCode = error.response?.body?.code ?? ``

    switch (errorCode) {
      case `basket_product_not_eligible`:
        setEligibilityModal(true)
        break
      case `basket_product_similarcoverit`: {
        setModalProps({
          title: t(`common:payment.page_step2_modal_title`),
          error: t(`common:payment.page_step2_modal_error`),
        })
        setEligibilityModal(true)
        break
      }
      case `basket_customerApi_ensura_error`: {
        sentryCaptureException(error, paymentErrorData)
        setErrorForm(t(`common:payment.page_step2_form_error`))
        break
      }
      case `user_email_invalid`: {
        setFieldError(`email`, t(`common:payment.page_step2_form_error2`))
        break
      }
      case `erreur_back_a_definir`: {
        setErrorForm(t(`common:payment.page_step2_form_error3`))
        break
      }
      case `collaborator_coveritVariant_not_eligible`: {
        setFieldError(
          `email`,
          `${t(`common:payment.page_step2_form_error5`)} ${brandName}. ${t(
            `common:payment.page_step2_form_error6`,
          )} ${brandName}.`,
        )
        break
      }
      case `collaborator_coveritVariant_already_subscribe`: {
        setFieldError(`email`, t(`common:payment.page_step2_form_error4`))
        break
      }
      default: {
        sentryCaptureException(error, paymentErrorData)
        setErrorForm(t(`common:payment.page_step2_form_error7`))
      }
    }

    setBtnActive(true)
  }

  const captcha = useCaptcha()
  const draft = useDraft(`step2`)

  const persist = usePersist(
    isReady,
    () => step2,
    (values, actions, { sessionId }) => {
      setStep2AndSessionId(omit(values, `billingPostalCodeAndCity`), sessionId)
    },
  )

  const valueChanges = useValueChanges(({ field, value, formik }) => {
    formTrackingErrors(`Tunnel - Coordonnées`, field, value, undefined, formik.errors[field])
  }, [])

  return (
    <TunnelCustomer currentStep={1} title={t(`common:payment.page_step2_payment_title`)} product={product}>
      <Form
        name="tunnel_customer_step2"
        captcha={captcha}
        draft={draft}
        persist={persist}
        valueChanges={valueChanges}
        initialValues={formInitialState}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFail={onSubmitFail}
        validate={validate}
      >
        <Field
          component={InputText}
          name="email"
          label={t(`common:payment.page_step2_form_email`) + ` * :`}
          notice={t(
            APP_CONFIG.featureFlags.selfcare
              ? `common:payment.page_step2_form_email_notice`
              : `common:payment.page_step2_form_email_notice_confirmation`,
          )}
          placeholder="exemple@domain.com"
        />
        <Field
          component={Checkbox}
          name="emailOptin"
          label={t(`common:payment.page_step2_form_email_option`)}
          color={grey.g800}
        />

        <Field
          component={RadioGroup}
          name="civility"
          label={t(`common:payment.page_step2_form_civility`) + ` * :`}
          items={[
            {
              label: t(`common:payment.page_step2_form_mr`),
              value: `mr`,
            },
            {
              label: t(`common:payment.page_step2_form_mme`),
              value: `mme`,
            },
          ]}
        />
        <Field
          component={InputText}
          name="firstname"
          label={`${t(`common:generic.form_field_first_name`)} * :`}
          placeholder={t(`common:generic.form_field_first_name`)}
          mask={cedilleMask}
        />
        <Field
          component={InputText}
          name="lastname"
          label={`${t(`common:generic.form_field_last_name`)} * :`}
          mask={cedilleMask}
          placeholder={t(`common:generic.form_field_last_name`)}
        />
        <hr />
        <Field
          component={InputText}
          name="phone"
          mask={telMask}
          label={`${t(`common:payment.page_step2_form_phone`)} * :`}
          placeholder={t(`common:payment.page_step2_form_phone`)}
        />
        <hr />
        <Field component={DifferentBilling} name="differentBilling" />
        <Address
          hide={values => !values.differentBilling}
          fieldNames={{
            postalCodeAndCity: `billingPostalCodeAndCity`,
            cp: `billingPostalCode`,
            city: `billingCity`,
            address: `billingAddress`,
            streetField: `billingStreet`,
            streetNumberField: `billingStreetNumber`,
            residence: `billingResidence`,
          }}
        />
        <hr />
        {errorForm && <Error data-testid="payment_step2_error">{errorForm}</Error>}
        <PaymentFormFooter>
          <AdviceText>{t(`common:payment.page_step2_advice_text`)}</AdviceText>

          <SubmitButton data-testid="tunnel_customer_step2_submit" disabled={!btnActive || !campaignProductReady}>
            {t(`common:generic.form_continue_button`)}
          </SubmitButton>
        </PaymentFormFooter>
      </Form>

      <PaymentCnilMention>{cnilMentionCustomerForm}</PaymentCnilMention>

      <Modal
        visible={eligibilityModal}
        closeModal={() => setEligibilityModal(false)}
        modalComponent={EligibilityModal}
        modalProps={modalProps}
      />
    </TunnelCustomer>
  )
}

Step2Page.propTypes = {
  data: PropTypes.shape({
    websiteConfigurationLocale: PropTypes.shape({
      cnilMentionCustomerForm: PropTypes.any,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query {
    websiteConfigurationLocale {
      cnilMentionCustomerForm
    }
  }
`
